const bgImages = {
	schoolDay: require("../assets/images/bg/School_Hallway_Day.webp"),
	classroomDay: require("../assets/images/bg/Classroom_Day.webp"),
	bedroomDay: require("../assets/images/bg/Bedroom_Day.webp"),
	bedroomEvening: require("../assets/images/bg/Bedroom_Evening.webp"),
	bedroomNight: require("../assets/images/bg/Bedroom_Night.webp"),
	bedroomNightDark: require("../assets/images/bg/Bedroom_Night_Dark.webp"),
	stage: require("../assets/images/bg/Stage.webp"),
	cafeteriaDay: require("../assets/images/bg/Cafeteria_Day.webp"),
	cityAfternoon: require("../assets/images/bg/City_Afternoon.webp"),
	cityMorning: require("../assets/images/bg/City_Morning.webp"),
	cityNight: require("../assets/images/bg/City_Night.webp"),
	cityRaining: require("../assets/images/bg/City_Raining.webp"),
	kitchenDay: require("../assets/images/bg/Kitchen_Day.webp"),
	kitchenNight: require("../assets/images/bg/Kitchen_Night.webp"),
	livingroomDark: require("../assets/images/bg/Livingroom_Dark.webp"),
	livingroomDay: require("../assets/images/bg/Livingroom_Day.webp"),
	livingroomNight: require("../assets/images/bg/Livingroom_Night.webp"),
	streetAutumnDay: require("../assets/images/bg/Street_Autumn_Day.webp"),
	streetAutumnEvening: require("../assets/images/bg/Street_Autumn_Evening.webp"),
	streetAutumnNight: require("../assets/images/bg/Street_Autumn_Night.webp"),
	streetSpringDay: require("../assets/images/bg/Street_Spring_Day.webp"),
	streetSpringEvening: require("../assets/images/bg/Street_Spring_Evening.webp"),
	streetSpringNight: require("../assets/images/bg/Street_Spring_Night.webp"),
	streetSpringRain: require("../assets/images/bg/Street_Spring_Rain.webp"),
	streetSummerDay: require("../assets/images/bg/Street_Summer_Day.webp"),
	streetSummerEvening: require("../assets/images/bg/Street_Summer_Evening.webp"),
	streetSummerNight: require("../assets/images/bg/Street_Summer_Night.webp"),
	streetSummerRain: require("../assets/images/bg/Street_Summer_Rain.webp"),
	streetSummerStars: require("../assets/images/bg/Street_Summer_Stars.webp"),
};

export default bgImages;
